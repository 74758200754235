import React, { FC } from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const givel: FC = () => {
  const titleImage = "_DSC2175.jpg"
  const image1 = "_DSC2148.jpg"
  const image2 = "_DSC2151.jpg"
  const image3 = "_DSC2160.jpg"
  const image4 = "_DSC2168.jpg"
  const image5 = "_DSC2186.jpg"

  return (
    <Layout>
      <div className="single-project">
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Givel/" + titleImage}
            alt="Ghidoni"
          />
        </div>
        <h3 className="single-project-picture-landscape">
          Givel 2019-2022
          <h5 className="single-project-text">
            La committenza ha acquistato una casa di vacanza in zona edificabile
            a Mergoscia e in tre anni hanno riammodernato gli interni,
            procedendo a tappe un piano all’anno. In particolare si sono
            demolite delle pareti interne, si è resa abitabile la soffitta
            sfruttando quanto ammesso dalla legge sulle abitazioni secondarie,
            si sono rifatti i bagni e la cucina. Esternamente la casa costruita
            negli anni 90 è rimasta invariata e appare abbastanza anonima,
            entrando in casa l’obiettivo è stato quello di poter stupire gli
            avventori con dettagli e finiture ricercate.
          </h5>
        </h3>
        <div>
          <StaticImage
            src={"../../assets/images/Givel/" + image2}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Givel/" + image3}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Givel/" + image1}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Givel/" + image4}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Givel/" + image5}
            alt="Alambicco"
          />
        </div>
      </div>
    </Layout>
  )
}

export default givel
